import React, { useCallback, useEffect, useState } from 'react';
import { ChainId } from '@ethpass/types/contract';
import { CheckIcon } from '@heroicons/react/outline';
import { classNames, Theme } from '../styles/style';
import { ellipsizeAddress } from '@ethpass/utils/format';
import { networks } from '@ethpass/core/provider';
import { Chains, Platform } from '@ethpass/types/pass';
import { useDownloadModalContext } from '../../contexts/downloadModal';
import { useAccount, useSigner } from 'wagmi';
import addAppleWallet from '../../public/assets/apple-wallet-add.png';
import addGoogleWallet from '../../public/assets/google-wallet-add.png';
import ConnectButton from '../wallet/ConnectButton';
import Image from 'next/image';
import Modal from '../shared/Modal';
import NFTSelector from './NFTSelector';
import Scanner from './DemoScanner';
import toast from 'react-hot-toast';
import { RadioGroup } from '@headlessui/react';
import { networkLogos } from '../icons/networks';
import { MoonpayWalletSDK } from '@moonpay/login-sdk';
import LoadingIndicator from '../shared/LoadingIndicator';
import CopyToClipboard from '../shared/CopyClipboard';

export enum Account {
  TRADITIONAL = 'traditional',
  MOONPAY = 'moonpay',
}

export default function Demo() {
  const { isConnected, address: traditionalAddress } = useAccount();
  const { data: traditionalWallet } = useSigner();
  const { showModal: showDownloadModal, open } = useDownloadModalContext();
  const [generated, setGenerated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [moonPay, setMoonPay] = useState(null);
  const [network, setNetwork] = useState(ChainId.MAINNET);
  const [payload, setPayload] = useState(null);
  const [pending, setPending] = useState<boolean>(false);
  const [scanned, setScanned] = useState<boolean>(false);
  const [showMoonPayModal, setShowMoonPayModal] = useState(false);
  const [installAttempt, setInstallAttempt] = useState(false);

  const [account, setAccount] = useState({
    address: '',
    type: '',
    isWalletActive: false,
    walletMetadata: null,
  });

  const disconnectAccount = () => {
    setAccount({
      address: '',
      type: '',
      isWalletActive: false,
      walletMetadata: null,
    });
  };

  // Traditional wallet
  useEffect(() => {
    if (isConnected) {
      setAccount({
        address: traditionalAddress,
        type: Account.TRADITIONAL,
        isWalletActive: true,
        walletMetadata: null,
      });
    } else {
      disconnectAccount();
    }
  }, [traditionalAddress, isConnected]);

  useEffect(() => {
    if (payload) createPass();
  }, [payload]);

  const moonPayLogin = async () => {
    try {
      // await moonPay.init();
      setIsLoading(true);

      const signer = moonPay.provider.getSigner();
      const address = await signer.getAddress();
      const walletMetadata = await moonPay.getWalletMetaData();
      setAccount({
        address,
        type: Account.MOONPAY,
        isWalletActive: true,
        walletMetadata,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  // MoonPay
  useEffect(() => {
    if (!network) return;
    registerMoonPay();
  }, [network]);

  const registerMoonPay = () => {
    const sdk = new MoonpayWalletSDK({
      loginDomain: 'https://buy.moonpay.com',
      secureWalletDomain: 'https://web3.moonpay.com',
      apiKey:
        process.env.NODE_ENV === 'development'
          ? 'pk_live_Cp0bHf9jZAFCLqTMIIy3ZhbaaYk087S'
          : 'pk_live_Cp0bHf9jZAFCLqTMIIy3ZhbaaYk087S',
    });
    sdk.init().then(() => {
      setMoonPay(sdk);
    });
  };

  const moonPayLogout = async () => {
    try {
      moonPay.disconnectWallet();
      moonPay.logout();
      moonPay.tearDown();

      setShowMoonPayModal(false);
      disconnectAccount();
      registerMoonPay();
    } catch (error) {
      console.log(error);
    }
  };

  const onNFTSelect = (payload) => {
    setPayload(payload);
  };
  // @todo: we cant switch chains if the wallet is not connected
  const renderNetworkSelector = () => {
    return (
      <RadioGroup value={network} onChange={setNetwork}>
        <div className="flex items-center space-x-3">
          {[ChainId.MAINNET, ChainId.MATIC].map((chainId) => {
            return (
              <RadioGroup.Option
                key={networks[Chains.EVM][chainId].name}
                value={chainId}
                className={({ active, checked }) =>
                  classNames(
                    network == chainId ? 'ring ring-offset-2 ring-indigo-500' : '',
                    '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                  )
                }>
                <RadioGroup.Label as="p" className="sr-only">
                  {networks[Chains.EVM][chainId].name}
                </RadioGroup.Label>
                <span
                  aria-hidden="true"
                  className={classNames(
                    'h-12 w-12 border border-black border-opacity-10 rounded-full p-2'
                  )}>
                  <Image src={networkLogos(Chains.EVM, chainId || 1)} width={44} height={44} />
                </span>
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
    );
  };

  const createPass = useCallback(async () => {
    if (pending) return;
    setPending(true);

    const { tokenId, contractAddress, image, chainId } = payload;
    if (tokenId === null || contractAddress === null) return;
    const signatureToast = toast.loading('Waiting for signature...');
    const signatureMessage = `Sign this message to generate a test pass with ethpass.xyz\n${Date.now()}`;
    try {
      let signature;
      if (account.type === Account.MOONPAY) {
        const signer = moonPay.provider.getSigner();
        signature = await signer.signMessage(signatureMessage);
      } else {
        signature = await traditionalWallet.signMessage(signatureMessage);
      }
      toast.dismiss(signatureToast);

      const pendingToast = toast.loading('Generating pass...');
      const payload = {
        address: account.address,
        signature,
        contractAddress,
        tokenId,
        signatureMessage,
        chainId,
        image,
      };
      const response = await fetch('/api/public/demo', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
          'content-type': 'application/json',
        }),
      });
      toast.dismiss(pendingToast);
      if (response.status === 200) {
        const distribution = await response.json();
        setPending(false);
        setGenerated(distribution);
      } else if (response.status === 401) {
        toast.error('Unable to verify ownership');
      } else {
        const { error, message } = await response.json();
        toast.error(error || message);
      }
    } catch (error) {
      let message = '';
      if (error.error && error.error.message) {
        message = error.error.message;
      } else if (error.code === 'CALL_EXCEPTION') {
        message =
          'Smart contract call failed. Double check that your contract address exists on the `chainId` param you have specified.';
      } else if (error.code === 'ACTION_REJECTED' || error.message === 'User rejected request') {
        // MetaMask - ACTION_REJECTED
        // Moonpay - error.message (User rejected the request)
        message = 'Signature request denied';
      } else {
        message = 'Unknown error. Check console logs';
      }
      toast.error(message);
    } finally {
      setPending(false);
      toast.dismiss(signatureToast);
    }
  }, [account, showDownloadModal, payload, pending]);

  const displayModal = useCallback(
    (platform: Platform) => {
      setInstallAttempt(true);
      showDownloadModal({ distribution: generated, platform });
    },
    [generated]
  );

  const renderHeader = () => {
    const steps = [
      {
        id: '01',
        name: 'Connect Wallet',
        status: account.isWalletActive ? 'complete' : 'current',
        onClick: () => {
          setAccount({ ...account, isWalletActive: false });
          setPayload(null);
        },
      },
      {
        id: '02',
        name: 'Select NFT',
        status: account.isWalletActive ? (generated ? 'complete' : 'current') : 'upcoming',
        onClick: () => {
          if (account && account.address) {
            setAccount({ ...account, isWalletActive: true });
          }
          setPayload(null);
          setGenerated(null);
        },
      },
      {
        id: '03',
        name: 'Install Pass',
        status: installAttempt ? 'complete' : generated ? 'current' : 'upcoming',
        onClick: () => {
          setInstallAttempt(false);
        },
      },
      {
        id: '04',
        name: 'Scan Pass',
        status: installAttempt ? 'current' : 'upcoming',
        onClick: () => {},
      },
    ];

    return (
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-8" aria-label="Progress">
        <ol
          role="list"
          className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.id} className="relative overflow-hidden lg:flex-1">
              <div
                onClick={() => step.onClick()}
                className={classNames(
                  stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                  stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                  'border border-gray-200 overflow-hidden lg:border-0 cursor-pointer'
                )}>
                {step.status === 'complete' ? (
                  <div className="group">
                    <span
                      className="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}>
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full">
                          <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col self-center">
                        <span className="text-s font-semibold tracking-wide uppercase">
                          {step.name}
                        </span>
                      </span>
                    </span>
                  </div>
                ) : step.status === 'current' ? (
                  <div aria-current="step">
                    <span
                      className="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}>
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                          <span className="text-indigo-600">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col self-center">
                        <span className="text-s font-semibold tracking-wide uppercase">
                          {step.name}
                        </span>
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="group">
                    <span
                      className="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? 'lg:pl-9' : '',
                        'px-6 py-5 flex items-start text-sm font-medium'
                      )}>
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                          <span className="text-gray-500">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col self-center">
                        <span className="text-s font-semibold tracking-wide uppercase">
                          {step.name}
                        </span>
                      </span>
                    </span>
                  </div>
                )}
                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div
                      className="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
                      aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none">
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    );
  };

  const renderStep = useCallback(() => {
    if (!payload || (payload && !generated)) {
      return (
        <NFTSelector onSelect={onNFTSelect} account={account} network={network} pending={pending} />
      );
    }
    if (generated) {
      if (!installAttempt) {
        return (
          <div className="py-24">
            <ul className="flex flex-col align-center justify-center md:flex-row">
              <li key={Platform.APPLE} className="relative md:mr-4 mb-4">
                <a
                  href={generated.apple.url}
                  target="_blank"
                  rel="noreferrer"
                  className="group block w-full aspect-w-10 aspect-h-7 overflow-hidden">
                  <Image src={addAppleWallet} width={160} height={48} />
                </a>
              </li>
              <li key={Platform.GOOGLE} className="relative">
                <a
                  href={generated.google.url}
                  target="_blank"
                  className="group block w-full aspect-w-10 aspect-h-7 overflow-hidden">
                  <Image src={addGoogleWallet} width={264} height={48} />
                </a>
              </li>
            </ul>
          </div>
        );
      } else {
        return <Scanner onScanComplete={setScanned} />;
      }
    }
  }, [payload, generated, account, network, pending, installAttempt, displayModal]);

  return (
    <>
      <div className="bg-white pt-16 px-4 sm:px-6 lg:pt-24 lg:px-8 mb-24">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl" id="demo">
          Live Demo
        </h2>
        {renderHeader()}

        {isLoading ? (
          <LoadingIndicator />
        ) : !account.isWalletActive ? (
          <div className="flex flex-col items-center justify-center gap-4 px-4 sm:px-6 lg:px-8">
            {!account.address ? (
              <>
                <p className="block font-semibold text-gray-500 text-md ">SELECT CHAIN</p>
                <div className="mb-4 border-b-2 px-12 pb-6">{renderNetworkSelector()}</div>
              </>
            ) : null}

            <ConnectButton
              theme={Theme.DARK}
              className={`max-w-sm ${account.type !== Account.MOONPAY ? 'block' : 'hidden'}`}
            />
            <p
              className={`${
                account.address ? 'hidden' : 'block font-semibold text-gray-500 text-sm'
              }`}>
              OR
            </p>
            <button
              type="button"
              className={`max-w-sm text-base font-dmsans font-medium hover:shadow text-indigo-600 py-3 flex items-center justify-center gap-2 md:py-4 md:px-6 rounded-xl w-full cursor-pointer md:mr-0 my-auto bg-white border border-indigo-400 hover:bg-indigo-100 ${
                account.type !== Account.TRADITIONAL ? 'block' : 'hidden'
              }`}
              onClick={
                account.type === Account.MOONPAY ? () => setShowMoonPayModal(true) : moonPayLogin
              }>
              <svg
                className="h-8"
                width="26"
                height="31"
                viewBox="0 0 26 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24.04 8.73593C24.9039 8.73593 25.7484 8.47975 26.4667 7.9998C27.185 7.51984 27.7448 6.83766 28.0754 6.03951C28.406 5.24137 28.4925 4.36312 28.324 3.51582C28.1555 2.66852 27.7395 1.89022 27.1286 1.27935C26.5177 0.668478 25.7394 0.25247 24.8921 0.0839312C24.0448 -0.0846077 23.1666 0.00189257 22.3684 0.332493C21.5703 0.663094 20.8881 1.22295 20.4081 1.94126C19.9282 2.65956 19.672 3.50406 19.672 4.36797C19.672 4.94159 19.7849 5.50959 20.0044 6.03956C20.2239 6.56952 20.5457 7.05105 20.9513 7.45666C21.3569 7.86227 21.8384 8.18401 22.3684 8.40351C22.8983 8.62301 23.4663 8.73597 24.04 8.73593ZM10.6467 28.4081C8.54093 28.4081 6.48245 27.7837 4.73157 26.6138C2.98069 25.4439 1.61605 23.7811 0.810208 21.8356C0.00436783 19.8901 -0.206476 17.7494 0.20434 15.6841C0.615155 13.6188 1.62918 11.7217 3.11818 10.2327C4.60718 8.7437 6.50429 7.72968 8.56959 7.31887C10.6349 6.90806 12.7756 7.11891 14.7211 7.92475C16.6666 8.7306 18.3294 10.0952 19.4993 11.8461C20.6692 13.597 21.2936 15.6555 21.2936 17.7612C21.2936 19.1594 21.0183 20.5439 20.4832 21.8357C19.9482 23.1274 19.1639 24.3012 18.1753 25.2898C17.1866 26.2785 16.0129 27.0627 14.7211 27.5978C13.4294 28.1328 12.0449 28.4082 10.6467 28.4081Z"
                  fill="#7D00FF"></path>
              </svg>
              {account.type === Account.MOONPAY
                ? ellipsizeAddress(account.address)
                : 'Sign In With MoonPay'}
            </button>
          </div>
        ) : (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">{renderStep()}</div>
        )}
      </div>
      <Modal isActive={showMoonPayModal} onClose={() => setShowMoonPayModal(false)}>
        <div className="flex flex-col items-center justify-center">
          <div className="p-4">
            <img
              width={150}
              src="https://upload.wikimedia.org/wikipedia/commons/b/b8/MoonPay.svg"
            />
          </div>
          <div className="flex flex-col justify-start w-full mb-8">
            {account?.address ? (
              <>
                <label className="text-xs font-bold text-gray-700">Wallet:</label>
                <p className="text-xs mb-4">
                  {account.address}
                  <CopyToClipboard text={account.address} />
                </p>
              </>
            ) : null}

            {account?.walletMetadata?.email ? (
              <>
                <label className="text-xs font-bold text-gray-700">Email:</label>
                <p className="text-xs mb-4">{account?.walletMetadata?.email}</p>
              </>
            ) : null}

            {account?.walletMetadata?.firstName ? (
              <>
                <label className="text-xs font-bold text-gray-700">First Name:</label>
                <p className="text-xs mb-4">{account?.walletMetadata?.firstName}</p>
              </>
            ) : null}

            {account?.walletMetadata?.lastName ? (
              <>
                <label className="text-xs font-bold text-gray-700">Last Name:</label>
                <p className="text-xs mb-4">{account?.walletMetadata?.lastName}</p>
              </>
            ) : null}
          </div>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:text-sm"
            onClick={moonPayLogout}>
            Disconnect
          </button>
        </div>
      </Modal>
    </>
  );
}
