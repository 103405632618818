import { Fragment, useEffect, useState } from 'react';
import { ChainId } from '@ethpass/types/contract';
import { CollectionIcon, XIcon } from '@heroicons/react/outline';
import { Dialog, Transition } from '@headlessui/react';
import Joi from 'joi';
import { networks } from '@ethpass/core/provider';
import LoadingIndicator from '../shared/LoadingIndicator';
import useSWRImmutable from 'swr/immutable';
import { getAPIHost } from '@ethpass/constants';
import fetchJson from '../../lib/fetcher';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
export const DemoFormValidation = Joi.object({
  contractAddress: Joi.string().min(42).max(42).required(),
  tokenId: Joi.number().required().unsafe(),
});

export default function NFTSelector({ onSelect, account, network, pending }) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [activeCollection, setActiveCollection] = useState<string>(null);
  const isActivating = false;
  const [validationError, setValidationError] = useState<Error | null>(null);

  const [formData, setFormData] = useState({
    contractAddress:
      process.env.NODE_ENV === 'development' ? '0x25ed58c027921e14d86380ea2646e3a1b5c55a8b' : null,
    tokenId: process.env.NODE_ENV === 'development' ? '5946' : null,
    image: '',
    chainId: 1,
  });

  const validInput = formData.contractAddress && formData.tokenId && !validationError;

  useEffect(() => {
    const { tokenId, contractAddress } = formData;
    const validate = DemoFormValidation.validate({ contractAddress, tokenId });
    if (validate.error) {
      setValidationError(validate.error);
    } else {
      setValidationError(null);
    }
  }, [formData]);

  const { data, error } = useSWRImmutable(
    account.address
      ? `${getAPIHost()}/api/sdk/assets?address=${account.address}&chainId=${network}`
      : null,
    (url) =>
      fetchJson(url, { headers: { 'x-api-key': process.env.NEXT_PUBLIC_ETHPASS_PUBLIC_KEY } })
  );
  const hasValidCollection = data?.collection && Object.keys(data?.collection).length;

  useEffect(() => {
    if (data?.collection) {
      const keys = Object.keys(data?.collection);
      if (keys.length) {
        keys.sort();
        setActiveCollection(keys[0]);
      } else {
        setActiveCollection(null);
      }
    }
  }, [data]);

  const renderNetworkSelect = () => {
    const chainList = Object.keys(networks.evm).map((chainId) => Number(chainId));
    return (
      <>
        <select
          defaultValue={chainList[0]}
          onChange={(event) => {
            setFormData({ ...formData, chainId: Number(event.target.value) });
          }}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
          {chainList.map((chain) => (
            <option key={chain} value={chain}>
              {networks[chain]?.name ?? chain}
            </option>
          ))}
        </select>
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="max-w-3xl mx-auto py-12">
        <div className="mb-8 p-4 border border-yellow-300/20 bg-yellow-300/20 text-yellow-700 rounded-lg">
          <h3 className="text-sm">
            We were unable to find any NFTs for this wallet. Please try entering the NFT details
            manually in the form below, or connect another wallet
          </h3>
        </div>
        <label htmlFor="contract" className="block text-sm font-medium text-gray-700">
          <span className="text-red-500">* </span>Contract Address
        </label>
        <div className="mt-1 mb-6">
          <input
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            required
            type="text"
            minLength={42}
            maxLength={42}
            value={formData.contractAddress}
            onChange={(e) => setFormData({ ...formData, contractAddress: e.target.value })}
          />
        </div>
        <label htmlFor="tokenId" className="block text-sm font-medium text-gray-700">
          <span className="text-red-500">* </span>Token ID
        </label>
        <div className="mt-1 mb-6">
          <input
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            required
            type="number"
            value={formData.tokenId}
            onChange={(e) => setFormData({ ...formData, tokenId: e.target.value })}
          />
        </div>
        <label htmlFor="network" className="block text-sm font-medium text-gray-700">
          <span className="text-red-500">* </span> Network
        </label>
        <div className="mt-1 mb-1">{renderNetworkSelect()}</div>
        <label htmlFor="image" className="block text-sm font-medium text-gray-700">
          Thumbnail Image URL <small>(PNG, JPG up to 500KB)</small>
        </label>
        <div className="mt-1 mb-6">
          <input
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            type="text"
            value={formData.image}
            onChange={(e) => setFormData({ ...formData, image: e.target.value })}
          />
        </div>

        <div className="flex flex-row justify-end">
          {hasValidCollection ? (
            <button
              disabled={pending}
              className="block text-sm font-medium text-gray-500 mr-4"
              onClick={() => setActiveCollection(Object.keys(data?.collection)[0])}>
              Cancel
            </button>
          ) : null}
          <button
            disabled={pending}
            className={classNames(
              'inline-flex items-center px-6 py-2 my-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white',
              !validInput
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
            onClick={() => validInput && onSelect(formData)}>
            Next
          </button>
        </div>
      </div>
    );
  };

  const renderNfts = (nfts) => {
    if (!nfts) return null;
    return nfts.map((nft) => (
      <li key={nft.id} className="relative">
        <button
          onClick={() =>
            onSelect({
              contractAddress: nft.contract.address,
              tokenId: nft.tokenId,
              platform: 'apple',
              image: nft.media.thumbnailUrl,
              chainId:
                nft.contract.chain === 'ethereum'
                  ? ChainId.MAINNET
                  : nft.contract.chain === 'polygon'
                  ? ChainId.MATIC
                  : ChainId.ARBITRUM,
            })
          }
          className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
          <img
            src={nft.media.imageUrl ? nft.media.imageUrl : '/assets/img-not-available.png'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = '/assets/img-not-available.png';
            }}
            alt="NFT"
            className="object-cover pointer-events-none group-hover:opacity-75"
          />
        </button>
        <p className="block text-sm font-medium text-gray-500 pointer-events-none mt-2">
          {nft.name}
        </p>
      </li>
    ));
  };

  const renderGrid = () => {
    return (
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full">
              <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Collections</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}>
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                {data?.collection ? (
                  <form className="mt-4">
                    <h3 className="sr-only">Categories</h3>
                    <ul role="list" className="font-medium text-gray-900 px-2 py-3">
                      {Object.keys(data?.collection)
                        .sort()
                        .map((key) => (
                          <li key={data?.collection[key]}>
                            <div
                              onClick={() => {
                                setActiveCollection(key);
                                setMobileFiltersOpen(false);
                              }}
                              className={classNames(
                                'block px-2 py-3 cursor-pointer rounded-md px-2',
                                key === activeCollection ? 'bg-indigo-50 ' : ''
                              )}>
                              {key}
                            </div>
                          </li>
                        ))}
                      {/* <li className="text-center py-4 border-t">
                        <a
                          className="text-sm text-indigo-700 font-bold cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            setMobileFiltersOpen(false);

                            setActiveCollection(null);
                          }}>
                          Try on Testnests or Sidechains
                        </a>
                      </li> */}
                    </ul>
                  </form>
                ) : null}
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <main className="max-w-7xl mx-auto px-2">
          <div className="relative z-10 flex items-center align-middle pb-6 border-b border-gray-200">
            <div className="flex items-center self-end">
              <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Collections</h2>

              <button
                type="button"
                className="p-2 sm:ml-6 text-gray-400 hover:text-gray-500 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}>
                <div className="flex">
                  <span className="sr-only">Filters</span>
                  <CollectionIcon className="w-5 h-5" aria-hidden="true" />
                  <span className="ml-1">View</span>
                </div>
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
              {/* Filters */}

              {data?.collection ? (
                <form className="hidden lg:block">
                  <h3 className="sr-only">Collections</h3>
                  <ul role="list" className="text-sm font-medium text-gray-900 space-y-4 pb-6">
                    {Object.keys(data?.collection)
                      .sort()
                      .map((key) => (
                        <li
                          key={key}
                          className={classNames(
                            'hover:bg-indigo-50 rounded-md px-2',
                            key === activeCollection ? 'bg-indigo-50 ' : ''
                          )}>
                          <div
                            onClick={() => {
                              setActiveCollection(key);
                              document
                                .getElementById('demo')
                                .scrollIntoView({ behavior: 'smooth' });
                            }}
                            className="block px-2 py-3 cursor-pointer">
                            {key}
                          </div>
                        </li>
                      ))}
                    {/* <li className="text-center py-4 border-t">
                      <a
                        className="text-sm text-indigo-700 font-bold cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setMobileFiltersOpen(false);

                          setActiveCollection(null);
                        }}>
                        Try on Testnests or Sidechains
                      </a>
                    </li> */}
                  </ul>
                </form>
              ) : null}

              {/* Product grid */}
              <div className="lg:col-span-3">
                {/* Replace with your content */}
                <ul
                  role="list"
                  className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                  {renderNfts(data.collection[activeCollection])}
                </ul>
                {/* /End replace */}
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  };
  return (
    <div className="bg-white">
      {!activeCollection && !isActivating && data ? (
        renderForm()
      ) : hasValidCollection ? (
        renderGrid()
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
}
