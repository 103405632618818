import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const faqs = [
  {
    question: 'How does it work?',
    answer: `ethpass works by issuing an Apple Wallet or Google Pay pass that is tied to an NFT. After a pass has been issued, we ensure that the ownership status of the token is properly reflected on the pass at any given time.`,
  },
  {
    question: 'Is it secure?',
    answer: `Yes. Your safety is our primary focus and is the main reason why ethpass is being built. We will never request access to your NFTs or any part of your wallet. Our solution is entirely non-custodial.`,
  },
  {
    question: 'What blockchains do you currently support?',
    answer: `We are currently live on Ethereum, Polygon, Optimism, Arbitrum, BSC, Gnosis and Base. Our platform is easily adaptable to any public or private EVM chain. Additionally, we are actively working to add support for non-EVM based chains, which will be coming soon.`,
  },
  {
    question: 'Why do I need to sign a message to generate my pass?',
    answer:
      'Signing a message is the only way we can truly know that you are the owner of the NFT you are generate a pass for. Signing is a safe, cost-less transaction that does not in any way give us permission to access your tokens or perform transactions with your wallet.',
  },
  {
    question: 'What crypto wallets do you support?',
    answer:
      'ethpass supports the top non-custodial wallets, and custodial wallets, including Magic Link and CrossMint.',
  },
  {
    question: 'If I transfer the NFT registered to my pass, will my pass still be valid?',
    answer: `No. Passes will only remain valid if the token remains in possession of the account used to generate the pass.`,
  },
  {
    question: 'When I connect my wallet, is Apple or Google able to track any of my private data?',
    answer:
      'Apple and Google do not have access to your NFTs or crypto wallet. They are unable to access the registration data that we store in our system to verify your assets. All of the information that is stored with ethpass is what is publically stored on the blockchain.',
  },
  {
    question: 'How do you verify ownership of NFTs?',
    answer: (
      <ul className="list-decimal list-inside">
        <li className="py-4">
          Before a pass is generated, the user signs a message to claim ownership of a token. We use
          this information to read from the smart contract in real time and verify that the owner of
          the token is the one requesting the pass.
        </li>
        <li className="py-4">
          Our service responds to token transfer events from smart contracts and will invalidate a
          pass if ownership has changed.
        </li>
        <li className="py-4">
          We validate ownership again by reading from the smart contract when the QR code on the
          pass is scanned.
        </li>
      </ul>
    ),
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl" id="faq">
            FAQ
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform'
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
