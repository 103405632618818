import React from 'react';
import { useModal } from 'connectkit';
import { useAccount } from 'wagmi';
import { ellipsizeAddress } from '@ethpass/utils/format';
import { classNames, Theme } from '../styles/style';
import { WalletMedia } from '../shared/WalletMedia';

export default function ConnectButton({
  theme = Theme.LIGHT,
  style,
  className: classNameInput,
}: {
  theme?: Theme;
  style?: any;
  className?: string;
}) {
  const { address, isConnected, isReconnecting, connector } = useAccount();
  const { setOpen } = useModal();
  const connectedWalletProvider = WalletMedia.find((option) => option.name === connector?.name);
  return (
    <button
      type="button"
      onClick={() => setOpen(true)}
      style={style}
      className={classNames(
        theme === Theme.LIGHT
          ? 'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
          : 'text-base font-dmsans font-medium hover:shadow text-white py-3 flex items-center justify-center md:py-4 md:px-6 rounded-xl w-full cursor-pointer md:mr-0 my-auto bg-indigo-600 hover:bg-indigo-500',
        classNameInput ? `${classNameInput}` : ''
      )}>
      {isConnected && !isReconnecting ? (
        <>
          <img className="w-6 h-6 flex-shrink-0 mr-2" src={connectedWalletProvider.image} alt="" />
          {ellipsizeAddress(address)}
        </>
      ) : (
        'Connect Wallet'
      )}
    </button>
  );
}
